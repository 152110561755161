import "./styles.scss";
import React from "react";

import { useLocale } from "context/locale";

import Button from "components/Button";

const PageInfo = ({ title, text, button, variant }) => {
  const { t } = useLocale();

  return (
    <section className={`not-found ${variant ? variant : ""}`}>
      <div className="container">
        <div className="not-found__header">
          <h1>{title}</h1>
        </div>
        <p>{text}</p>
        {!!button && <Button href={button?.href}>{t(button?.label)}</Button>}
      </div>
    </section>
  );
};

export default PageInfo;
