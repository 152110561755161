import React from "react";

import Layout from "components/Layout";
import PageInfo from "components/PageInfo";

const HubWork = ({ location }) => {
  return (
    <Layout
      location={location}
      seo={{
        title: "Thank you",
        description: "",
      }}
    >
      <PageInfo
        title="Message sent"
        text="Thank you for contacting us. Our staff will reply as soon as possible."
        button={{
          label: "Home",
          href: "/en/",
        }}
      />
    </Layout>
  );
};

export default HubWork;
